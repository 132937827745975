<template>
  <div class="row m-2">
    <div class="col-lg-4 col-sm-6">
      <h4>{{getTrans('messages.on_camsite')}}</h4>
      <el-select
        clearable
        filterable
        type="primary"
        v-if="getCamsites"
        v-model="filter.platform"
      >
        <el-option value :label="getTrans('messages.any_platform')"></el-option>
        <el-option
          v-for="camsite in getCamsites"
          :key="camsite.id"
          :value="camsite.id"
          :label="camsite.title"
        ></el-option>
      </el-select>
    </div>
    <div class="col-lg-4 col-sm-6">
      <h4>{{getTrans('messages.model_name')}}</h4>
      <div class="input-suffix">
        <el-input
          :placeholder="getTrans('messages.search_performer')"
          prefix-icon="el-icon-search"
          v-model="filter.name"
        ></el-input>
      </div>
    </div>
    <div class="col-lg-4 col-sm-6 mt-4">
      <ProfilesAvatar
        :profiles="Object.values(selectedProfiles)"
        :showTooltip="false"
        :show-delete="true"
        @delete="removeProfile"
      ></ProfilesAvatar>
    </div>
  </div>
  <div class="row result-container">
    <div class="col-12 py-4" v-if="loading">
      <content-loader></content-loader>
    </div>
    <div class="col-12 scrolled-content-wrapper">
      <ProfilesCardList :profiles="profiles" :selectLimit="selectLimit" v-model:selected="selectedProfiles"></ProfilesCardList>
    </div>
    <div v-if="!loading && (!getPerformers || !getPerformers.length)" class="col-12">
      <div class="row align-items-center">
        <div class="col-12">
          <BaseAlert :type="hasSearched ? 'warning' : 'primary'">
            <span class="mr-2">
              <i class="fas fa-info-circle"></i>
            </span>
            <strong v-if="hasSearched">{{getTrans('messages.no_results')+'... '}}</strong> {{getTrans('messages.no_performer_results_details')}}
          </BaseAlert>
        </div>
      </div>
    </div>
  </div>
  <div class="row m-2" v-if="filter.platform === 4">
    <div class="col-12 text-center">
      <base-alert type="secondary">
        <span>
          <i class="fas fa-question-circle mr-4"></i>
        </span>
        <strong>{{getTrans('extra.info_chaturbate_title')}}</strong> {{getTrans('extra.info_chaturbate_text')}}
        <a
          href="https://support.divatraffic.com/knowledgebase/article/why-is-my-profile-showing-offline-when-im-online-on-chaturbate"
        >{{getTrans('extra.info_chaturbate_link')}}</a>
      </base-alert>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ElSelect, ElInput, ElOption } from 'element-plus';
import BaseAlert from '@/components/BaseAlert.vue';
import ProfilesCardList from '@/views/Profiles/Components/ProfilesCardList';
import ProfilesAvatar from "@/views/Orders/Components/ProfilesAvatar";

export default {
  name: 'performer-search',
  emits: ['update:selectedPerformers'],
  components: {
    [ElOption.name]: ElOption,
    [ElSelect.name]: ElSelect,
    [ElInput.name]: ElInput,
    BaseAlert,
    ProfilesCardList,
    ProfilesAvatar,
  },
  props: {
    selectLimit: {
      type: Number,
      default: null
    },
    selectedPerformers: {
      type: Array,
      default: () => {
        return [];
      }
    },
  },
  data() {
    return {
      selectedProfiles: {},
      filter: {
        platform: '',
        name: '',
      },
      hasSearched: false,
      selected: {...this.selectedPerformers}
    }
  },
  computed: {
    ...mapGetters('Platform', ['getCamsites']),
    ...mapGetters('Performer', {getPerformers: 'getList' , loading: 'listLoading', errors: 'getErrors'}),
    profiles() {
      // Simulate profiles to use ProfilesCardList component.
      const res = [];
      this.getPerformers.forEach(performer => {
        res.push({id: performer.id, performer: {data: performer}, type: 1})
      })
      return res;
    },
  },
  methods: {
    search() {
      if (this.filter.name.length > 3) {
        this.$store.dispatch('Performer/search', {search: this.filter.name, platform_id: this.filter.platform}).then(() => {
          this.hasSearched = true;
        });
      }
    },
    removeProfile(profile) {
      delete this.selectedProfiles[profile.id];
    }
  },
  watch: {
    filter: {
      handler() {
        this.search();
      },
      deep: true
    },
    selectedProfiles() {
      this.selected = [];
      for (let id in this.selectedProfiles) {
        this.selected.push(this.selectedProfiles[id].performer.data);
      }
      this.$emit('update:selectedPerformers', this.selected);
    },
    selectedPerformers(newValue) {
      if (newValue !== this.selected) {
        // Transform selected for ProfilesCardList component.
        const res = {};
        newValue.forEach(performer => {
          res[performer.id] = {id: performer.id, performer: {data: performer}, type: 1};
        })
        this.selectedProfiles = res;
      }
    },
  },
  unmounted() {
    this.$store.dispatch('Performer/resetList');
  },
}
</script>
