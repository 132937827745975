<template>
  <div>
    <div clas="row">
      <div class="col-12">
        <AddProfilePage :userId="user.id" :show-cancel-button="false"></AddProfilePage>
      </div>
    </div>
    <div class="ml-3 mr-3">
      <ProfilesTable :profiles="profilesList" :showModel="false"></ProfilesTable>
    </div>
  </div>
</template>
<script>

import AddProfilePage from "@/views/Profiles/AddProfilePage.vue";
import ProfilesTable from "@/views/Profiles/Components/ProfilesTable";

export default {
  emits: ['update:selected', 'sorted'],
  components: {
    AddProfilePage,
    ProfilesTable
  },
  props: {
    user: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  computed: {
    profilesList() {
      try{
        return this.user.profiles.data;
      } catch (e) {
        return [];
      }
    },
  }
}
</script>
