<template>
  <div class="card profiles-table__selected-profiles">
    <div class="card-body" v-if="Object.keys(selectedProfiles).length">
      <div>{{getTrans(linkView ? 'messages.selected_links' : 'messages.selected_profiles')}}: </div>
      <div class="d-flex justify-content-between">
        <ProfilesAvatar
          :profiles="Object.values(selectedProfiles)"
          :showTooltip="false"
          :show-delete="true"
          @delete="profileClicked"
        ></ProfilesAvatar>
        <base-button type="primary" @click="boostProfiles(Object.values(selectedProfiles))">{{getTrans('messages.boost')}}</base-button>
      </div>
    </div>
  </div>
  <div class="profiles-table__container">
    <div class="profiles-table__row-group header" :class="{'link': linkView, 'no-model': !showModel}" role="row-group">
      <div class="profiles-table__row text-left" role="column-header">{{ getTrans(linkView ? 'messages.links' : 'messages.profile_info') }}</div>
      <div class="profiles-table__row justify-content-center" role="column-header" v-if="linkView">{{ getTrans('messages.platform') }}</div>
      <div class="profiles-table__row text-left" role="column-header" v-if="showModel">{{ getTrans('messages.model') }}</div>
      <div class="profiles-table__row justify-content-center" role="column-header" v-if="!linkView">{{ getTrans('messages.status') }}</div>
      <div class="profiles-table__row justify-content-center" role="column-header" v-if="!linkView">
        <Sortable class="justify-content-center" :sorting="sorting.show_in_ads ?? null" @sorted="sort($event, 'show_in_ads')">
        <span>{{getTrans('messages.show_in_ads')}}
          <el-tooltip placement="top" :content="getTrans('messages.show_in_ads_details')">
            <i class="fas fa-question-circle pl-2"></i>
          </el-tooltip>
          </span>
        </Sortable>
      </div>
      <div class="profiles-table__row justify-content-center" role="column-header" v-if="!linkView">{{ getTrans('messages.max_viewers') }}</div>
      <div class="profiles-table__row justify-content-center" role="column-header" v-if="!linkView">{{ getTrans('messages.viewers') }}</div>
      <div class="profiles-table__row justify-content-center" role="column-header">{{ getTrans('messages.actions') }}</div>
    </div>
    <div class="profiles-table__row-group" role="row-group"
      :class="{'link': linkView, 'no-model': !showModel, 'profiles-table__row-group-selected': selectedProfiles[profile.id]}"
      v-for="profile in profiles"
      :key="profile.id"
      @click="profileClicked(profile)"
    >
      <div class="profiles-table__row" :class="{'link': linkView}">
        <profiles-avatar :profiles="[profile]" @click.stop=""></profiles-avatar>
      </div>
      <div class="profiles-table__row link p-0 justify-content-center" v-if="linkView">
        <img class="profile-platform" :src="platformLogo(profile.platform_id)"
      />
      </div>
      <div class="profiles-table__row"  v-if="showModel">{{ profile.model?.data?.name }}</div>
      <div class="profiles-table__row justify-content-center"  v-if="!linkView">
        <strong class="profiles-table__row-header">{{ getTrans('messages.status') }}:&nbsp;</strong>
        <ProfileStatus :profile="profile"></ProfileStatus>
      </div>
      <div class="profiles-table__row justify-content-center" v-if="!linkView">
        <strong class="profiles-table__row-header">{{getTrans('messages.show_in_ads')}}:&nbsp;</strong>
        <base-switch
         :disabled="(loading || (!showInAdsActive && !profile.show_in_ads))"
         :modelValue="profile.show_in_ads ? true : false"
         @input="onShowInAdsChange($event, profile)"
         @click.stop=""
        ></base-switch>
      </div>
      <div class="profiles-table__row justify-content-center" v-if="!linkView">
        <strong class="profiles-table__row-header">{{ getTrans('messages.max_viewers') }}:&nbsp;</strong>
        {{ roomLimit(profile) }}
      </div>
      <div class="profiles-table__row justify-content-center" v-if="!linkView">
        <strong class="profiles-table__row-header">{{ getTrans('messages.viewers') }}:&nbsp;</strong>
        {{ viewers(profile) }}
      </div>
      <div class="profiles-table__row justify-content-center"  @click.stop="">
        <DivaDropdown :title="'messages.boost'" :titleValue="'boost'" :options="dropdownValues" @click="dropdownClick($event, profile)"></DivaDropdown>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import ProfilesAvatar from "@/views/Orders/Components/ProfilesAvatar";
import ProfileStatus from "@/views/Profiles/Components/ProfileStatus";
import ProfileMixin from "@/mixins/Profile/ProfileMixin";
import ProfileViewersMixin from "@/mixins/Profile/ProfileViewersMixin";
import DivaDropdown from "@/components/Diva/DivaDropdown.vue";
import Sortable from "@/components/Sortable";
import PlatformMixin from "@/mixins/PlatformMixin";

export default {
  name: 'ProfilesTable',
  mixins: [ProfileMixin, ProfileViewersMixin, PlatformMixin],
  emits: ['update:selected', 'sorted'],
  components: {
    ProfilesAvatar,
    ProfileStatus,
    DivaDropdown,
    Sortable
  },
  props: {
    profiles: {
      type: Array,
      required: true
    },
    selected: {
      type: Object,
      default: () => {
        return {};
      }
    },
    showModel: {
      type: Boolean,
      default: true
    },
    sorting: {
      type: Object,
      default: () => {
        return {};
      }
    },
    linkView: {
      type: Boolean,
      default: () => {
        return false;
      }
    }
  },
  data() {
    return {
      availableShowInAds: 0,
      enabledShowInAds: 0,
    };
  },
  computed: {
    ...mapGetters('User', {
      user: 'getData',
      userCan: 'can',
    }),
    ...mapGetters('Profile', {
      loading: 'loading',
      getProfileType: 'getType',
    }),
    selectedProfiles() {
      return {...this.selected};
    },
    dropdownValues() {
      const values = [];
      if (this.userCan('profiles-update')) {
        values.push({title: "messages.edit", value: "edit"});
      }
      if (this.userCan('profiles-delete')) {
        values.push({title: "messages.delete", value: "delete"});
      }
      if (this.userCan('statistic')) {
        values.push({title: "messages.stats", value: "stats"});
      }
      return values;
    },
    showInAdsActive() {
      return this.enabledShowInAds < this.availableShowInAds;
    },
  },
  methods: {
    showInAds(profile) {
      return !profile.show_in_ads;
    },
    profileClicked(profile) {
      let selected = this.selectedProfiles;
      if (selected[profile.id]) {
        delete selected[profile.id];
      } else {
        selected[profile.id] = profile;
      }
      this.$emit('update:selected', this.selectedProfiles);
    },
    viewers(profile) {
      const performer = profile.performer.data;
      return (performer.online && performer.viewer_count > 0) ? performer.viewer_count : '-'
    },
    dropdownClick(event, profile) {
      switch (event) {
        case 'boost':
          this.boostProfiles([profile]);
          break;
        case 'edit':
          this.editProfile(profile);
          break;
        case 'delete':
          this.$swal.fire({
            title: this.getTrans('messages.are_you_sure'),
            text: this.getTrans('messages.delete_profile_text', {name: this.profileName(profile)}),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.getTrans("messages.delete"),
            cancelButtonText: this.getTrans("messages.cancel"),
          }).then(async result => {
            if (result.isConfirmed) {
              this.$store.dispatch('Profile/delete', profile.id);
            }
          });
          break;
        case 'stats':
          break;
      }
    },
    async onShowInAdsChange(event, profile) {
      this.$store.dispatch('Profile/update', {
        data: {id: profile.id, show_in_ads: event ? 1 : 0},
        include: ['model', 'defaultMaxMinViewers']
      }).then(() => {
        event ? this.enabledShowInAds++ : this.enabledShowInAds--;
      });
    },
    sort(sorting, column) {
      this.$emit('sorted', {sorting: sorting, column: column});
    },
    boostProfiles(profiles) {
      this.$router.push({name: 'boost', params: {profiles: JSON.stringify(profiles)}});
    }
  },
  mounted() {
    const availableProfilesInAds = this.user.profilesInAds;
    this.availableShowInAds = availableProfilesInAds.available;
    this.enabledShowInAds = availableProfilesInAds.enabled;
  }
}
</script>

<style scoped>

</style>
