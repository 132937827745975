
<template>
  <el-dropdown
    split-button
    type="primary"
    @click="clicked(titleValue)"
    trigger="click"
    size="small"
    class="diva-dropdown disabled"
  >
    <span
    :class="buttonClasses" class="title" >{{getTrans(title)}}</span>
    <template #dropdown>
    <el-dropdown-menu :class="iconClasses">
      <el-dropdown-item
        v-for="(option, i) in optionsToDisplay"
        :key="option.value ? option.title + option.value : option.title"
      >
        <a @click="clicked(option.value ? option.value : i, option)">{{getTrans(option.title)}}</a>
      </el-dropdown-item>
    </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import { ElDropdownMenu, ElDropdownItem, ElDropdown } from "element-plus";
export default {
  name: "diva-dropdown",
  emits: ['click'],
  components: {
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu
  },
  props: {
    title: String,
    titleValue: {
      type: [String, Number],
      default: null
    },
    options: {
      type: Array,
      default: function() {
        return [{ title: "No options", value: null }];
      }
    },
    iconClasses: {
      type: String,
      default: ()=>{
        return '';
      }
    },
    buttonClasses:{
      type: String,
      default: ()=>{
        return '';
      }
    },
    disabled: {
      type: [Boolean,Number],
      default: ()=>{
        return false;
      }
    }
  },
  computed: {
    optionsToDisplay() {
      return this.options.filter(o => {
        if (o.hide) {
          return o.hide() ? false : true;
        } else {
          return true;
        }
      });
    }
  },
  methods: {
    clicked(value, option) {
      if (option && typeof option.click === 'function') {
        option.click();
      } else {
        this.$emit("click", value);
      }
    }
  }
};
</script>

<style>

</style>
