<template>
  <div class="sorting-wrapper d-flex align-items-center" @click="sort">
    <slot></slot>
    <span class="sorting-wrapper-arrows ml-1">
      <span class="sorting-arrow-asc mb-1" :class="sorting === 'asc' ? 'sorting-arrow-asc-active' : ''"></span>
      <span class="sorting-arrow-desc" :class="sorting === 'desc' ? 'sorting-arrow-desc-active' : ''"></span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Sortable',
  emits: ['sorted'],
  props: {
    sorting: {
      type: String,
      default: null
    }
  },
  methods: {
    sort() {
      let sorting = 'desc';
      if (this.sorting === null) {
        sorting = 'asc';
      } else if (this.sorting === 'desc') {
        sorting = null;
      }
      this.$emit('sorted', sorting);
    }
  }
};
</script>
