<template>
  <diva-card>
    <div v-loading="saving">
      <PerformerSearch :selectLimit="5" v-model:selectedPerformers="selectedPerformers"></PerformerSearch>
      <div class="row m-2">
        <div class="col-12 text-center">
          <base-button type="secondary" @click="cancel" v-if="showCancelButton">{{getTrans('messages.cancel')}}</base-button>
          <base-button type="primary" @click="addProfiles" :disabled="selectedPerformers.length === 0">{{getTrans('messages.add')}}</base-button>
        </div>
      </div>
    </div>
  </diva-card>
</template>
<script>

import { mapGetters } from 'vuex';
import PerformerSearch from "@/views/Profiles/AddProfiles/PerformerSearch";
import Errors from "@/mixins/Errors";

export default {
  mixins: [Errors],
  components: {
    PerformerSearch,
  },
  props: {
    userId: {
      type: Number,
      default: null
    },
    showCancelButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectedPerformers: [],
      saving: false,
    };
  },
  computed: {
    ...mapGetters('Profile', {
      errors: 'getErrors'
    }),
    ...mapGetters('User', ['getUser']),
  },
  methods: {
    cancel() {
      this.$router.push({ name: 'profiles' });
    },
    async addProfiles() {
      this.saving = true;
      const user_id = this.userId ?? this.getUser.id;
      const errored = [];
      let performer;
      while (this.selectedPerformers.length) {
        performer = this.selectedPerformers.pop();
        await this.$store.dispatch('Profile/create', { performer_id: performer.id, user_id: user_id }).catch(() => {});
        if (this.errors) {
          await this.alertErrors(performer?.name);
          errored.push(performer);
        } else {
          this.$toast.success(performer?.name + ': ' + this.getTrans('messages.profile_added'));
        }
      }
      // Push profiles with error back.
      if (errored.length) {
        this.selectedPerformers.push(...errored);
      }
      // Recreate, for PerformerSearch watch function.
      this.selectedPerformers = [...this.selectedPerformers];
      this.saving = false;
    },
    setPerformers(performers) {
      this.selectedPerformers = performers;
    },
  },
};
</script>

